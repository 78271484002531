<script>
/**
 * Alerts component
 */
import moment from "moment";
//import {vaccinState} from "./cash"
import {
  alertsMethods,
  devicesMethods,
  devicesComputed,
  authComputed,
} from "@/state/helpers";
import AquitAlert from "./aquit-alert";
import Logs from "./logs-data";

import "sweetalert2/dist/sweetalert2.min.css";

export default {
  props: ["alerts", "causes", "actions", "operations", "states", "busy", "loaderStatus"],
  components: {
    AquitAlert,
    Logs,
  },
  data() {
    return {
      alert: {},
      alertsList: [],
      selectedAlerts: [],
      pageOptions: [5, 10, 25, 50],
      sortDesc: false,
      //vaccinState : vaccinState,
      device: null,
      loader: true,
    };
  },
  computed: {
    ...authComputed,
    ...devicesComputed,
    selectStatus() {
      if (this.selectedAlerts.length < 1) return false;
      else return true;
    },
    fieldsComputed2() {
      if (this.loggedIn.user.role != "USER") {
        return [
          {
            value: "date",
            sortable: false,
            text: this.$t("dataTable.dateTime.text"),
          },
          { value: "serial", sortable: false, text: "Serial" },
          { value: "ref", sortable: false, text: "Reference" },
          {
            value: "name",
            sortable: false,
            text: this.$t("dataTable.name.text"),
          },
          {
            value: "region",
            sortable: false,
            text: this.$t("dataTable.region.text"),
          },
          {
            value: "province",
            sortable: false,
            text: this.$t("dataTable.province.text"),
          },
          {
            value: "state",
            sortable: false,
            text: this.$t("dataTable.status.text"),
          },
          {
            value: "stockType",
            sortable: false,
            text: this.$t("dataTable.stockType.text"),
          },
          {
            value: "vaccinTypes",
            sortable: false,
            text: this.$t("dataTable.vaccinType.text"),
          },
          {
            value: "type",
            sortable: false,
            text: this.$t("dataTable.type.text"),
          },
          {
            value: "value",
            sortable: false,
            text: this.$t("dataTable.value.text"),
          },
          {
            value: "actions",
            sortable: false,
            text: this.$t("dataTable.tools.text"),
          },
        ];
      } else {
        return [
          {
            value: "date",
            sortable: false,
            text: this.$t("dataTable.dateTime.text"),
          },
          { value: "serial", sortable: false, text: "Serial" },
          { value: "ref", sortable: false, text: "Reference" },
          {
            value: "name",
            sortable: false,
            text: this.$t("dataTable.name.text"),
          },
          {
            value: "region",
            sortable: false,
            text: this.$t("dataTable.region.text"),
          },
          {
            value: "province",
            sortable: false,
            text: this.$t("dataTable.province.text"),
          },
          {
            value: "state",
            sortable: false,
            text: this.$t("dataTable.status.text"),
          },
          {
            value: "stockType",
            sortable: false,
            text: this.$t("dataTable.stockType.text"),
          },
          {
            value: "vaccinTypes",
            sortable: false,
            text: this.$t("dataTable.vaccinType.text"),
          },
          {
            value: "type",
            sortable: false,
            text: this.$t("dataTable.type.text"),
          },
          {
            value: "value",
            sortable: false,
            text: this.$t("dataTable.value.text"),
          },
        ];
      }
    },
    fieldsComputed() {
      if (this.loggedIn.user.role != "USER") {
        return [
          {
            key: "date",
            sortable: false,
            label: this.$t("dataTable.dateTime.text"),
          },
          { key: "serial", sortable: false, label: "Serial" },
          {
            key: "name",
            sortable: false,
            label: this.$t("dataTable.name.text"),
          },
          {
            key: "region",
            sortable: false,
            label: this.$t("dataTable.region.text"),
          },
          {
            key: "province",
            sortable: false,
            label: this.$t("dataTable.province.text"),
          },
          {
            key: "state",
            sortable: false,
            label: this.$t("dataTable.status.text"),
          },
          {
            key: "stockType",
            sortable: false,
            label: this.$t("dataTable.stockType.text"),
          },
          {
            key: "type",
            sortable: false,
            label: this.$t("dataTable.type.text"),
          },
          {
            key: "value",
            sortable: false,
            label: this.$t("dataTable.value.text"),
          },
          {
            key: "actions",
            sortable: false,
            label: this.$t("dataTable.tools.text"),
          },
        ];
      } else {
        return [
          {
            key: "date",
            sortable: false,
            label: this.$t("dataTable.dateTime.text"),
          },
          { key: "serial", sortable: false, label: "Serial" },
          {
            key: "name",
            sortable: false,
            label: this.$t("dataTable.name.text"),
          },
          {
            key: "region",
            sortable: false,
            label: this.$t("dataTable.region.text"),
          },
          {
            key: "province",
            sortable: false,
            label: this.$t("dataTable.province.text"),
          },
          {
            key: "state",
            sortable: false,
            label: this.$t("dataTable.status.text"),
          },
          {
            key: "stockType",
            sortable: false,
            label: this.$t("dataTable.stockType.text"),
          },
          {
            key: "type",
            sortable: false,
            label: this.$t("dataTable.type.text"),
          },
          {
            key: "value",
            sortable: false,
            label: this.$t("dataTable.value.text"),
          },
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...alertsMethods,
    ...devicesMethods,

    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalLogs() {
      this.$bvModal.show("logs-modal");
    },
    showModalAquit() {
      this.$bvModal.show("aquit-modal");
    },
    hideModalAquit() {
      this.$bvModal.hide("aquit-modal");
    },
    clear() {
      this.alertAquit = {
        alertId: null,
        state: null,
        action: null,
        operation: null,
        cause: null,
        savedDosesSino: null,
        savedDosesFizer: null,
        note: null,
      };
    },
    aquit(alert) {
      this.loader = true;
      let alertFilter = this.cleanObject(alert);
      this.hideModalAquit();
      this.aquitAlert(alertFilter).then(() => {
        this.loader = false;
      });
    },
    openDevice(device) {
      let routerLink = this.$router.resolve({
          name: "device",
          params: { serial: device.serial },
        })
      window.open(routerLink.href, '_blank');
    },
    passAlert(alert) {
      this.alert = alert;
      this.showModalAquit();
    },
    passAlerts() {
      this.$emit("alertsToAquit", { alertsToAquit: this.selectedAlerts });
      this.selectedAlerts = [];
    },
    rowClicked(record) {
      this.getDevicesBy({ serial: record.serial });
      this.showModalLogs();
    },
    cleanObject(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  watch: {
    alerts(newVal) {
      this.selectedAlerts = [];
      let alertsListCustom = newVal.map((obj) => {
        let vaccinTypes = obj.vaccinTypes.map(vaccin=> {return vaccin.name});
        return {
          ...obj,
          state: obj.state ? obj.state.name : null,
          province: obj.province ? obj.province.name : null,
          region: obj.region ? obj.region.name : null,
          date: moment(obj.alertedAt).format("DD-MM-YYYY HH:mm:ss"),
          stockType: obj.stockType ? obj.stockType : "-",
          vaccinTypes: vaccinTypes ? vaccinTypes : "-"
          /* vaccinState:
            obj.serial in this.vaccinState ? this.vaccinState[obj.serial] != "" ? this.vaccinState[obj.serial] : "-" : "-", */
        };
      });
      this.alertsList = alertsListCustom;
    },
    getDevices(newVal) {
      this.device = newVal.results[0];
    },
    busy(newVal){
      this.loader = newVal;
    }
  },
};
</script>

<template>
  <div>
    <div v-if="selectStatus && loggedIn.user.role != 'USER'" class="row">
      <b-button class="mb-4 ml-3" variant="light" @click="passAlerts">
        {{ $t("buttons.aquit.text") }}
      </b-button>
    </div>
    <div
      class="table-responsive mb-3 bg-white rounded-lg"
      style="min-height: 430px"
    >
      <!-- <b-table
      class="mb-0"
      hover
      show-empty
      centred
      :busy="busy"
      :empty-text="this.$t('dataTable.noAlerts.text')"
      :items="alertsList"
      :fields="fieldsComputed"
      v-bind:style="{ cursor: `pointer` }"
      stacked="sm"
      :sort-desc.sync="sortDesc"
      @row-clicked="rowClicked"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <strong>{{ $t("dataTable.loading.text") }}</strong>
        </div>
      </template>

      <template v-slot:cell(state)="row">
        <div
          class="badge font-size-12 text-center"
          :class="{
            'badge-soft-warning': `${row.value}` === 'NEW',
            'badge-soft-info': `${row.value}` === 'OPENED',
            'badge-soft-secondary': `${row.value}` === 'SUSPENDED',
            'badge-soft-success': `${row.value}` === 'CLOSED',
          }"
        >
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(type)="row">
        <div
          class="badge font-size-12 text-center"
          :class="{
            'badge-soft-primary': `${row.value}` === 'DOWN',
            'badge-soft-danger': `${row.value}` === 'UP',
          }"
        >
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(value)="row">
        <div
          class="font-size-14 text-center font-weight-bold"
          :class="{
            'text-danger': `${row.value}` > 8,
            'text-primary': `${row.value}` < 2,
          }"
        >
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <b-button
              type="button"
              class="ml-2"
              variant="secondary"
              @click="passAlert(data.item)"
              >{{ $t("buttons.aquit.text") }}
            </b-button>
          </div>
        </div>
      </template>
      <template v-slot:cell(date)="row">
        <div class="text-center">
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(vaccinState)="row">
        <div class="text-center" style="min-width: 80px">
          {{ row.value }}
        </div>
      </template>
    </b-table> -->
      <v-data-table
        v-model="selectedAlerts"
        :headers="fieldsComputed2"
        :items="alertsList"
        :items-per-page="500"
        :single-select="false"
        :loading="busy"
        :loading-text="this.$t('dataTable.loading.text')"
        :no-data-text="this.$t('dataTable.noAlerts.text')"
        item-key="id"
        show-select
        hide-default-footer
        class="elevation-1"
        v-bind:style="{ cursor: `pointer` }"
        @click:row="rowClicked"
      >
        <template v-slot:[`item.state`]="{ item }">
          <div
            class="badge font-size-12 text-center"
            :class="{
              'badge-soft-warning': `${item.state}` === 'NEW',
              'badge-soft-info': `${item.state}` === 'OPENED',
              'badge-soft-secondary': `${item.state}` === 'SUSPENDED',
              'badge-soft-success': `${item.state}` === 'CLOSED',
            }"
          >
            {{ item.state }}
          </div>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <div
            class="badge font-size-12 text-center"
            :class="{
              'badge-soft-primary': `${item.type}` === 'DOWN',
              'badge-soft-danger': `${item.type}` === 'UP',
            }"
          >
            {{ item.type }}
          </div>
        </template>
        <template v-slot:[`item.value`]="{ item }">
          <div
            class="font-size-14 text-center font-weight-bold"
            :class="{
              'text-danger': `${item.value}` > 8,
              'text-primary': `${item.value}` < 2,
            }"
          >
            {{ item.value }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="row">
            <div class="col-6">
              <b-button-group>
                <b-button variant="secondary" @click.stop="passAlert(item)"
                  >{{ $t("buttons.aquit.text") }}
                </b-button>
                <b-button variant="light" @click.stop="openDevice(item)"
                  ><i class="ri-eye-line"></i
                ></b-button>
              </b-button-group>
            </div>
          </div>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <div class="text-center">
            {{ item.date }}
          </div>
        </template>
        <template v-slot:[`item.vaccinState`]="{ item }">
          <div class="text-center" style="min-width: 80px">
            {{ item.vaccinState }}
          </div>
        </template>
      </v-data-table>
      <AquitAlert
        v-bind:alert="alert"
        :causes="causes"
        :operations="operations"
        :actions="actions"
        :states="states"
        @aquitAlert="aquit"
      />
      <Logs v-bind:device="device" />
    </div>
  </div>
</template>