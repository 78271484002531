var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"logs-modal",attrs:{"id":"logs-modal","size":"xl","scrollable":"","hide-footer":"","title":this.$t('titles.logs.text')}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",attrs:{"id":"tableLogs"}},[_c('div',{staticClass:"table-responsive mb-3 bg-white rounded-lg",staticStyle:{"min-height":"300px"}},[_c('b-table',{staticClass:"mb-0",attrs:{"hover":"","show-empty":"","centred":"","busy":_vm.busy,"empty-text":"No Logs","items":_vm.logsList,"fields":_vm.fieldsComputed,"stacked":"sm"},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t("dataTable.loading.text")))])])]},proxy:true},{key:"cell(state)",fn:function(row){return [_c('div',{staticClass:"badge font-size-12",class:{
                  'badge-soft-warning': `${row.value}` === 'NEW',
                  'badge-soft-info': `${row.value}` === 'OPENED',
                  'badge-soft-secondary': `${row.value}` === 'SUSPENDED',
                  'badge-soft-danger': `${row.value}` === 'CLOSED',
                  'badge-soft-dark': `${row.value}` === 'IGNORED',
                }},[_vm._v(" "+_vm._s(row.value)+" ")])]}},{key:"cell(savedDoses)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.value)}})]}},{key:"cell(value)",fn:function(row){return [_c('div',{staticClass:"font-size-14 text-center font-weight-bold",class:{
                  'text-danger': `${row.value}` > 8,
                  'text-primary': `${row.value}` < 2,
                }},[_vm._v(" "+_vm._s(row.value)+" ")])]}}])})],1)])]),_c('div',{staticClass:"row mb-2"},[(_vm.moreLogs)?_c('div',{staticClass:"col-6"},[(!_vm.moreLoader && _vm.moreLogs)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-button',{attrs:{"id":"moreLogs","type":"button","variant":"info"},on:{"click":function($event){return _vm.showMore()}}},[_vm._v(_vm._s(_vm.$t("buttons.moreLogs.text"))+" ")])],1)]):_vm._e(),(_vm.moreLoader)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"position":"relative","z-index":"200"}},[_c('b-spinner',{attrs:{"label":"Loading...","variant":"primary"}})],1)])]):_vm._e()]):_vm._e(),(_vm.moreLogs)?_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.openDevice(_vm.device)}}},[_vm._v(_vm._s(_vm.$t("buttons.viewDevice.text")))])],1)])]):_vm._e(),(!_vm.moreLogs)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.openDevice(_vm.device)}}},[_vm._v(_vm._s(_vm.$t("buttons.viewDevice.text")))])],1)])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }