<template>
  <div>
    <b-modal
      id="aquit-alerts-modal"
      ref="aquit-alerts-modal"
      size="md"
      @hidden="cancel"
      :title="this.$t('modals.acquitAlerts.text')"
    >
      <!-- Status -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="state">{{ $t("modals.status.text") }}</label>
          <b-form-select
            id="state"
            v-model="alertAquit.state"
            :options="alertStates"
            class="mb-1"
            :state="err.state ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.state }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Operations -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="operations">Operations</label>
          <b-form-select
            id="operations"
            v-model="alertAquit.operation"
            :options="alertOperations"
            class="mb-1"
            :state="err.operation ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.operation }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Actions -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="actions">{{ $t("modals.actions.text") }}</label>
          <b-form-select
            id="actions"
            v-model="alertAquit.action"
            :options="alertActions"
            class="mb-1"
            :state="err.action ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.action }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Causes -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="causes">{{ $t("modals.causes.text") }}</label>
          <b-form-select
            id="causes"
            v-model="alertAquit.cause"
            :options="alertCauses"
            class="mb-1"
            :state="err.cause ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.cause }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- savedDoses -->
      <div class="row">
        <div class="col-12">
          <label for="vaccins">{{ $t("modals.savedDoses.text") }}</label>
          <div class="row">
            <div
              id="vaccins"
              v-for="(item, index) in vaccintypes"
              :key="index"
              class="col-3"
            >
              <b-form-input
                :id="item.id"
                type="number"
                min="0"
                v-model="alertAquit.savedDoses[item.name]"
                :placeholder="item.name"
                class="mb-1"
                :state="err.savedDoses ? false : null"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ err.savedDoses }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>

        <!-- <div class="col-6">
          <label for="savedDosesSino">{{$t('modals.savedDosesSino.text')}}</label>
          <b-form-input
            id="savedDosesSino"
            type="number"
            min="0"
            v-model="alertAquit.savedDosesSino"
            :placeholder="this.$t('textInput.enterSavedDosesSino.text')"
            class="mb-1"
            :state="err.savedDosesSino ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.savedDosesSino }}
          </b-form-invalid-feedback>
        </div>
        <div class="col-6">
          <label for="savedDosesFizer">{{$t('modals.savedDosesFizer.text')}}</label>
          <b-form-input
            id="savedDosesFizer"
            type="number"
            min="0"
            v-model="alertAquit.savedDosesFizer"
            :placeholder="this.$t('textInput.enterSavedDosesFizer.text')"
            class="mb-1"
            :state="err.savedDosesFizer ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.savedDosesFizer }}
          </b-form-invalid-feedback>
        </div> -->
      </div>

      <!-- Note -->
      <div class="row">
        <div class="col-10 col-lg-10">
          <label for="note">{{ $t("modals.note.text") }}</label>
          <b-form-textarea
            id="note"
            :rows="5"
            v-model="alertAquit.note"
            :placeholder="this.$t('textInput.enterNote.text')"
            class="mb-1"
            :state="err.note ? false : null"
          ></b-form-textarea>
          <b-form-invalid-feedback>
            {{ err.note }}
          </b-form-invalid-feedback>
        </div>
        <div class="col-2 col-lg-2 text-center">
          <label for="hours">{{ $t("modals.hours.text") }}</label>
          <b-form-input
            id="hours"
            type="range"
            max="5"
            min="1"
            v-model="alertAquit.hours"
            :placeholder="this.$t('textInput.enterHours.text')"
            class="mb-1"
            :state="err.hours ? false : null"
          ></b-form-input>
          {{ alertAquit.hours }}
          <b-form-invalid-feedback>
            {{ err.hours }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="aquit(ok)">
              {{ $t("buttons.aquit.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["alerts", "states", "operations", "actions", "causes", "vaccintypes"],

  created() {},
  data() {
    return {
      alertStates: [{ value: null, text: "Status", disabled: true }],
      alertOperations: [{ value: null, text: "Operation", disabled: true }],
      alertActions: [{ value: null, text: "Action", disabled: true }],
      alertCauses: [{ value: null, text: "Cause", disabled: true }],
      alertAquit: {
        devices: null,
        state: null,
        operation: null,
        action: null,
        cause: null,
        note: null,
        hours: 1,
        savedDoses: {},
        /* savedDosesSino: null,
        savedDosesFizer: null, */
      },
      err: {
        devices: null,
        state: null,
        operation: null,
        action: null,
        cause: null,
        note: null,
        hours: null,
        savedDoses: null,
        /* savedDosesSino: null,
        savedDosesFizer: null, */
      },
    };
  },
  watch: {
    alerts(newVal) {
      this.alertAquit.devices = [];
      let serials = [];
      for (let alert in newVal) {
        if (!serials.includes(newVal[alert].serial)) {
          serials.push(newVal[alert].serial);
          this.alertAquit.devices.push({
            serial: newVal[alert].serial,
            dateAquit: newVal[alert].createdAt,
          });
        }
      }
    },
    states(newVal) {
      let statesData = newVal;
      statesData.map((obj) => {
        if (obj.name != "NEW")
          this.alertStates.push({ value: obj.id, text: obj.name });
      });
    },
    causes(newVal) {
      let causesData = newVal;
      causesData.map((obj) => {
        this.alertCauses.push({ value: obj.id, text: obj.name });
      });
    },
    operations(newVal) {
      let operationsData = newVal;
      operationsData.map((obj) => {
        this.alertOperations.push({ value: obj.id, text: obj.name });
      });
    },
    actions(newVal) {
      let actionsData = newVal;
      actionsData.map((obj) => {
        this.alertActions.push({ value: obj.id, text: obj.name });
      });
    },
  },
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      let aquitObject = {
        state: null,
        operation: null,
        action: null,
        cause: null,
        note: null,
        hours: 1,
        savedDoses: {},
      };
      Object.assign(this.alertAquit, aquitObject);
      this.err = {
        state: null,
        operation: null,
        action: null,
        cause: null,
        note: null,
        hours: null,
        savedDoses: null,
      };
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        }
      });
      return obj;
    },
    aquit(ok) {
      if (!this.alertAquit.state)
        this.err.state = this.$t("msgError.obligField.text");
      else {
        this.err.state = "";
        let state = this.states.filter(
          (obj) => obj.id == this.alertAquit.state
        );
        if (!this.alertAquit.operation && state[0].isClosed)
          this.err.operation = this.$t("msgError.obligField.text");
        else this.err.operation = "";

        if (!this.alertAquit.action && state[0].isClosed)
          this.err.action = this.$t("msgError.obligField.text");
        else this.err.action = "";

        if (!this.alertAquit.cause && state[0].isClosed)
          this.err.cause = this.$t("msgError.obligField.text");
        else this.err.cause = "";
      }

      if (!this.err.state && !this.err.operation && !this.err.action && !this.err.cause) {
        ok();
        let vaccinsKeys = Object.keys(this.alertAquit.savedDoses);
        let vaccins = null;
        if(vaccinsKeys.length > 0) 
          vaccins = vaccinsKeys.map(obj=>{
            return {
              vaccin: obj,
              number: this.alertAquit.savedDoses[obj]
            }
          })
        let alertsData = {
          devices: this.alertAquit.devices,
          state: this.alertAquit.state ? this.alertAquit.state : null,
          operation: this.alertAquit.operation ? this.alertAquit.operation : null,
          action: this.alertAquit.action ? this.alertAquit.action : null,
          cause: this.alertAquit.cause ? this.alertAquit.cause : null,
          note: this.alertAquit.note ? this.alertAquit.note : null,
          hours: this.alertAquit.hours ? this.alertAquit.hours : 1,
          savedDoses: vaccins
            ? vaccins
            : null,
        };
        let filtredAlerts = this.cleanObject(alertsData);
        this.$emit("aquitAlert", filtredAlerts);
      }
    },
  },
};
</script>